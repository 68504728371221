import { Stack } from "@mui/material";
import H1 from "../../../core/H1";
import { ItemList } from "./core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SupplierServices } from "../../../services/SupplierServices";

const PMSupplierNormalProduct = () => {
  const [shopname, setShopName] = useState("");

  const { id } = useParams();
  const [data, setData] = useState({
    _id: "",
    shopName: "",
    shopNumber: "",
  });

  const getSupplierDetail = async (id: string) => {
    try {
      const response = await SupplierServices.getAllSupplierSmallApi(id);
      if (response.data) {
        setData(response.data.data);
      }
    } catch (error: any) {
      console.log("error in line no 32");
    }
  };

  useEffect(() => {
    if (id) {
      getSupplierDetail(id);
    }
  }, [id]);

  return (
    <>
      <Stack pb={5}>
        <H1 title={data.shopName} />
        <H1 title={"All Item Listss "} />
      </Stack>

      <ItemList />
    </>
  );
};
export default PMSupplierNormalProduct;
