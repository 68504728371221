import { Box, IconButton, InputBase, Stack, Typography } from "@mui/material";

interface QuentityEnterInterFace {
  quantity: number;
  nug?: string | number;
  pl?: number;
  pr?: number;
  placeholder?: string;
  width?: string;
}

const Input = ({
  quantity,
  nug,
  pl,
  pr,
  placeholder,
  width,
}: QuentityEnterInterFace) => {
  return (
    <Box
      height={"100%"}
      width={width ? width : "60px"}
      display={"flex"}
      alignItems={"center"}
      pl={pl}
      pr={pr}
    >
      <Typography fontSize={"13px"} pt={0.5} pr={1}>
        {nug}
      </Typography>
      <InputBase
        sx={{
          border: (theme) => `1px dashed ${theme.palette.divider}`,
          borderRadius: "8px",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
        placeholder={placeholder ? placeholder : ""}
        size="medium"
        inputProps={{
          min: 0,
          max: 999,
          style: { textAlign: "center" }, // Apply text align style directly to the input element
        }}
        value={quantity > 0 ? quantity : ""}
        type="number"
        onChange={(e: any) => alert("call")}
      />
    </Box>
  );
};
export default Input;
