import { InstanceAxios } from "../hooks/axiosConfig";
import { IAddContainer } from "../models/IContainer";

export class ContainerService {
  public static getContainerList() {
    return InstanceAxios().get("/container/getContainer");
  }

  public static addNewContainer(data: IAddContainer) {
    return InstanceAxios().post("/container/addContainer", data);
  }
}
