import { ITHead } from "../../../models/Common/ITHead";

export const AllSupplierList: ITHead[] = [
  {
    id: 1,
    name: "shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "No of Items",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const AllItemList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "PCS",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "Qty",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Actual Weight",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Rate No",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Rate",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "sellOn",
    isMove: true,
    align: "center",
  },

  {
    id: 3,
    name: "Selling (%)",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Selling Price",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Type",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "(%)",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Total",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const MobileListSupplier: ITHead[] = [
  {
    id: 1,
    name: "shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "No of Items",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];
